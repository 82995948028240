import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@app/components/atoms/Link';
import Spacer from '@app/components/atoms/Spacer';

const logo = '/images/sv_logo_2.png';
const drawerWidth = '50%';
const navItems = [
  {
    id: 1,
    name: 'CONCEPTS',
    path: '#concepts',
  },
  {
    id: 2,
    name: 'FEATURES',
    path: '#features',
  },
  {
    id: 3,
    name: 'OUR TEAM',
    path: '#ourteam',
  },
  {
    id: 4,
    name: 'PACKAGES',
    path: '#packages',
  },
  {
    id: 5,
    name: 'USE SCIVENIA',
    path: 'login',
  },
];

export default function LandingHeader() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavItemClick = (path) => {
    if (path === 'login') {
      window.location.href = path;
      window.scrollTo(0, 0); // Ensure the page starts at the top
    } else {
      const element = document.querySelector(path);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setMobileOpen(false);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Spacer height="1rem" />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              onClick={() => handleNavItemClick(item.path)}
              sx={{ textAlign: 'center' }}
            >
              <ListItemText primary={item.name} className="leading-6 sci-nav-item text-sm" />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <header className="sci-header sticky top-0 w-full z-50 shadow-sm shadow-gray-200/50 backdrop-blur-xl blur-safari">
      <div className="mx-auto flex items-center justify-between p-2 md:px-8" aria-label="Global">
        <div className="flex md:flex-1">
          <Link href="homepage" className="-m-1.5 p-1.5">
            <img className="h-9 w-auto" src={logo} alt="SciVenia Logo" />
          </Link>
        </div>
        <div className="md:flex md:flex-2 md:justify-end items-center">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            smooth
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {navItems.map((item) => (
              <Link
                href={item.path}
                key={item.id}
                naked
                smooth
                onClick={() => handleNavItemClick(item.path)}
              >
                <ListItemText primary={item.name} className="leading-6 sci-nav-item text-sm md:mx-4" />
              </Link>
            ))}
          </Box>
        </div>
      </div>

      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#6E789C1A',
              backdropFilter: 'blur(20px)',
            },
          }}
          anchor="right"
        >
          {drawer}
        </Drawer>
      </nav>
    </header>
  );
}
